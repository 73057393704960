import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import { useGetWindowHeight } from 'hooks'
import styles from './Feedback.module.css'
import { ReactComponent as CloseIcon } from 'assets/close.svg'

export type FeedbackProps = {
  title?: string
  content: string
  extraContent?: string
  boldedContent?: boolean
}

export const Feedback: FC<FeedbackProps> = ({
  title = 'Gratulacje!',
  content,
  extraContent,
  children,
  boldedContent = false,
}) => {
  const height = useGetWindowHeight()
  const history = useHistory()

  return (
    <>
      <CloseIcon
        className="absolute right-8 top-8 cursor-pointer"
        onClick={() => history.replace('/')}
      />
      <div className={styles.outerContainer} style={{ minHeight: height }}>
        <div className={styles.container}>
          <div className={styles.textContainer}>
            <p className={clsx(styles.textTitle, 'text-xl', 'xs:text-2xl')}>
              {title}
            </p>
            <p
              className={clsx(
                styles.textContent,
                boldedContent ? 'font-semibold' : 'font-medium',
              )}>
              {content}
            </p>
            {extraContent && (
              <p
                className={clsx(
                  styles.textContent,
                  boldedContent ? 'font-semibold' : 'font-medium',
                )}>
                {extraContent}
              </p>
            )}
          </div>
          {children}
        </div>
      </div>
    </>
  )
}
