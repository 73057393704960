import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { ParishCard, ParishCardProps, Loader, Button } from 'components'
import { fetchParishes } from 'services'
import { responseDataSelector } from 'utils'
import styles from './ParishesCardList.module.css'
import { GAEvent } from 'utils/googleAnalyticsUtils'

export const ParishesCardList = () => {
  const {
    data: parishes,
    error,
    isLoading,
    isLoadingError,
  } = useQuery('/parishes', fetchParishes, { select: responseDataSelector })

  if (isLoading && !isLoadingError) {
    return <Loader />
  }

  if (isLoadingError) {
    return <p>{`wystąpił błąd podczas ładowania danych, ${error}`}</p>
  }

  if (!isLoading && !isLoadingError) {
    if (parishes) {
      return (
        <>
          {parishes.map((parish: ParishCardProps, index: number) => (
            <div key={parish.id} className={styles.container}>
              <ParishCard {...parish}>
                <Link to={`parishes/${parish.id}`}>
                  <Button
                    onButtonClick={() =>
                      GAEvent('Main', 'Przejście do widoku wsparcia parafii')
                    }
                    buttonStyle="primary"
                    optionalStyles={'mt-4'}>
                    Wesprzyj parafię
                  </Button>
                </Link>
              </ParishCard>
              {parishes[index + 1] && <div className={styles.separator} />}
            </div>
          ))}
        </>
      )
    } else {
      return <p>Brak danych do wyświetlenia</p>
    }
  }
  return null
}
