import { useCallback, useState } from 'react'

export const useToggle = () => {
  const [on, setOn] = useState(false)
  const toggle = useCallback(() => {
    setOn(!on)
  }, [on])
  return {
    toggle,
    on,
  }
}
