import { useParams } from 'react-router-dom'
import {
  AppHeaderWithMenu,
  Button,
  CheckboxInput,
  Loader,
  ObligatoryRegulationsText,
} from 'components'
import { useAuth, useFetchParishOrGoalDetails, usePayments } from 'hooks'
import styles from './PaymentScreen.module.css'
import { useState } from 'react'

type ParamProps = {
  parishId: string
  goalId: string
  amount: string
}

export type GoalDetailsProps = {
  name?: string
  description?: string
}

export const PaymentScreen = () => {
  const auth = useAuth()
  const [
    areRegulationsConfirmed,
    setAreRegulationsConfirmed,
  ] = useState<boolean>(!!auth.isAuthenticated)
  const { amount, parishId, goalId } = useParams<ParamProps>()

  const {
    name,
    isLoading,
    isLoadingError,
    error,
  } = useFetchParishOrGoalDetails({ goalId, parishId, readyToFetchData: true })

  const { makePayment, isPaymentLoading } = usePayments({
    goalId,
    parishId,
  })

  return (
    <AppHeaderWithMenu>
      {isLoading && <Loader size={30} />}
      {isLoadingError && (
        <p>Nie udało się pobrać danych dotyczących płatności</p>
      )}
      {!isLoading && !error && (
        <>
          <div className={styles.outerContainer}>
            <div className={styles.nameText}>{name}</div>
            <div className={styles.separator} />
            <p className={styles.subHeadline}>Wysokość wpłaty:</p>
            <p className={styles.amountText}>{amount} PLN</p>
          </div>
          <div className={styles.buttonsContainer}>
            {auth.isAuthenticated || (
              <CheckboxInput
                checked={areRegulationsConfirmed}
                onChange={() =>
                  setAreRegulationsConfirmed(!areRegulationsConfirmed)
                }
                content={<ObligatoryRegulationsText />}
                name="acceptRegulationsCheck"
              />
            )}
            <Button
              optionalStyles="my-2"
              onButtonClick={() => makePayment(Number(amount))}
              disabled={isPaymentLoading || !areRegulationsConfirmed}>
              Przekaż datek
            </Button>
          </div>
        </>
      )}
    </AppHeaderWithMenu>
  )
}
