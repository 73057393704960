import React, { FC, useState } from 'react'
import { useMutation } from 'react-query'
import { Button, InfoElement, TextInput } from 'components'
import { ResponseError } from 'services'
import { useForm } from 'react-hook-form'
import { activateAccount } from 'services/accountService'
import { responseDataSelector } from 'utils'
import clsx from 'clsx'
import { AxiosError } from 'axios'

export const ParishAccountActivation: FC = () => {
  const [errorDetails, setErrorDetails] = useState('')

  const mutation = useMutation((token: string) => activateAccount(token), {
    onSuccess: (data) => {
      const redirectUrl = responseDataSelector(data).redirect_url
      window.open(redirectUrl, '_blank')
    },
    onError: (error: AxiosError) => {
      if (error.response?.status === 404) {
        setErrorDetails('Wystąpił błąd spróbuj ponownie')
        return
      }
      const errorDetails = error.response?.data.errors.find(
        (error: ResponseError) => error.source === 'blue_media',
      ).details

      setErrorDetails(errorDetails)
    },
  })

  const { register, errors, handleSubmit } = useForm({
    mode: 'onTouched',
  })

  const onSubmit = (values: { token: string }) => mutation.mutate(values.token)

  return (
    <div className="m-6">
      <h1 className="text-center text-lg font-bold">Podaj token aktywacyjny</h1>
      <form
        className="flex flex-col items-center justify-center m-6"
        onSubmit={handleSubmit(onSubmit)}
        noValidate>
        <TextInput
          label="Podaj token"
          placeholder="Wpisz token"
          type="text"
          name="token"
          defaultValue=""
          ref={register({
            required: true,
          })}
          error={errors.token && 'Token wymagany'}
        />
        <Button
          type="submit"
          disabled={mutation.isLoading}
          optionalStyles="mt-3">
          Wyślij token
        </Button>
        <div
          className={clsx(
            'mt-4',
            mutation.isError ? 'opacity-100' : 'opacity-0',
          )}>
          <InfoElement content={errorDetails} error={!!errorDetails} />
        </div>
      </form>
    </div>
  )
}
