import { FC } from 'react'
import { useQuery } from 'react-query'
import { fetchParishGoal } from 'services'
import { responseDataSelector } from 'utils'
import { Loader } from '../../Loader/Loader'
import { CollapsibleCard } from '../../CollapsibleCard/CollapsibleCard'
import { useFormattedIbanNumber } from 'hooks'

interface Props {
  parishId: string
  goalId: string
}

interface GoalDetailsProps {
  iban_number: string
  name: string
  description: string
}

export const GoalDetailsCard: FC<Props> = ({ parishId, goalId }) => {
  const { data: goalDetails, isLoading, error, isLoadingError } = useQuery(
    ['parishGoalDetails', parishId],
    () => fetchParishGoal(parishId, goalId),
    {
      onSuccess: (data: GoalDetailsProps) => data,
      select: responseDataSelector,
    },
  )

  const formattedIbanNumber = useFormattedIbanNumber(goalDetails?.iban_number)

  return (
    <div className="flex flex-col items-center my-4 p-4 pb-1 w-full rounded-3xl shadow-custom">
      {isLoading && !isLoadingError ? (
        <Loader />
      ) : (
        goalDetails && (
          <>
            <CollapsibleCard
              title={<p className="text-md font-bold">{goalDetails.name}</p>}
              GACategoryName="Zbiórki">
              <div className="flex flex-col items-center mt-4 px-1 w-full">
                <p className="text-center text-md font-medium">
                  {goalDetails.description}
                </p>
              </div>
            </CollapsibleCard>
            <span className="mb-2 text-primary text-md font-semibold">
              Numer konta parafii:
            </span>
            <span className="mb-4 text-md font-semibold">
              {formattedIbanNumber}
            </span>
          </>
        )
      )}
      {isLoadingError && (
        <p>{`Wystąpił błąd podczas ładowania danych, ${error}`}</p>
      )}
    </div>
  )
}
