import { api } from './api'
import { PaymentDraftProps, VerifyPaymentProps } from './types'

export const verifyPayment = ({
  orderId,
  serviceId,
  hash,
}: VerifyPaymentProps) =>
  api.get(
    `/payments/verify?order_id=${orderId}&service_id=${serviceId}&hash=${hash}`,
  )

export const getPaymentDetails = (id: string) => {
  return api.get(`payments/${id}`)
}

export const startPayment = (id: string) => api.post('/payments', { id })

export const paymentDraft = (props: PaymentDraftProps) =>
  api.post('/payments/draft', { payment: { ...props } })
