import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { paymentDraft, startPayment } from 'services'
import { responseDataSelector } from 'utils'

type PaymentProps = {
  goalId: string
  parishId: string
}

type PaymentReturnProps = {
  makePayment: (value: number) => void
  isPaymentLoading: boolean
  isPaymentError: boolean
}

export const usePayments = ({
  parishId,
  goalId,
}: PaymentProps): PaymentReturnProps => {
  const history = useHistory()

  const paymentDraftMutation = useMutation(
    (value: number) =>
      paymentDraft({
        amount: {
          fractional: value,
        },
        goal_id: goalId === parishId ? '' : goalId,
        parish_id: goalId === parishId ? parishId : '',
      }),
    {
      onSuccess: (data) =>
        paymentMutation.mutate(responseDataSelector(data).id),
      onError: () => history.replace('/payments'),
    },
  )

  const paymentMutation = useMutation((id: string) => startPayment(id), {
    onSuccess: (data) => {
      window.open(responseDataSelector(data).redirect_url, '_self')
    },
    onError: () => history.replace('/payments'),
  })

  const isPaymentLoading =
    paymentDraftMutation.isLoading || paymentMutation.isLoading
  const isPaymentError = paymentDraftMutation.isError || paymentMutation.isError

  return {
    makePayment: paymentDraftMutation.mutate,
    isPaymentLoading,
    isPaymentError,
  }
}
