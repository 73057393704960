import { FC } from 'react'
import clsx from 'clsx'

type Props = {
  status: string
}

export const PaymentStatus: FC<Props> = ({ status }) => {
  const baseStyles = 'font-medium text-md text-center'

  if (status === 'success')
    return (
      <span className={clsx(baseStyles, 'text-success')}>
        Płatność zrealizowana
      </span>
    )
  if (status === 'pending' || 'draft')
    return (
      <span className={clsx(baseStyles, 'text-pending')}>
        W trakcie realizacji
      </span>
    )
  if (status === 'failure')
    return (
      <span className={clsx(baseStyles, 'text-error')}>
        Płatność niezrealizowana
      </span>
    )
  return null
}
