import { FC } from 'react'
import { useParams } from 'react-router-dom'
import {
  ParishDetailsCard,
  AppHeaderWithMenu,
  ChooseDonationButtons,
} from 'components'
import { useToggle } from 'hooks'
import styles from './ParishDetails.module.css'

type ParamProps = {
  id: string
}

export const ParishDetails: FC = () => {
  const { id: parishId } = useParams<ParamProps>()
  const { on: customDonation, toggle: toggleCustomDonation } = useToggle()

  return (
    <AppHeaderWithMenu>
      <div className={styles.contentContainer}>
        <ParishDetailsCard parishId={parishId} />
        <ChooseDonationButtons
          parishId={parishId}
          goalId={parishId}
          customDonation={customDonation}
          toggleCustomDonation={toggleCustomDonation}
          GACategoryName="Parafia"
        />
      </div>
    </AppHeaderWithMenu>
  )
}
