import { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import {
  Button,
  CheckboxInput,
  ObligatoryRegulationsText,
  TextInput,
} from 'components'
import { useAuth, usePayments } from 'hooks'
import { GAEvent } from 'utils'
import styles from './ChooseDonationButtons.module.css'

export type ChooseDonationButtonsProps = {
  parishId: string
  goalId: string
  customDonation: boolean
  toggleCustomDonation?(): void
  GACategoryName?: string
}

type FormProps = {
  value: number
}

export const ChooseDonationButtons: FC<ChooseDonationButtonsProps> = ({
  parishId,
  goalId,
  customDonation,
  toggleCustomDonation,
  GACategoryName = 'Global',
}) => {
  const auth = useAuth()
  const [
    areRegulationsConfirmed,
    setAreRegulationsConfirmed,
  ] = useState<boolean>(!!auth.isAuthenticated)
  const { errors, handleSubmit, control } = useForm({
    mode: 'onChange',
  })

  const { makePayment, isPaymentLoading } = usePayments({ goalId, parishId })

  const onSubmit = ({ value }: FormProps) => {
    GAEvent(GACategoryName, `Wpłata ${value} zł `)
    makePayment(value)
  }
  return (
    <div className={styles.container}>
      <p className={styles.text}>
        {customDonation ? 'Wpisz kwotę' : 'Wybierz wysokość wpłaty'}
      </p>
      {customDonation ? (
        <form
          className={styles.formContainer}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          noValidate>
          <Controller
            name="value"
            rules={{
              required: {
                value: true,
                message: 'Minimalna wartość to 1 PLN',
              },
              max: {
                value: 1000,
                message: 'Maksymalna wartość to 1000 PLN',
              },
            }}
            defaultValue=""
            render={(props) => (
              <TextInput
                placeholder="Podaj kwotę (1-1000)"
                label="Kwota"
                hideLabel
                type="text"
                inputMode="numeric"
                error={errors.value && errors.value.message}
                {...props}
                onChange={(e) => {
                  props.onChange(
                    e.target.value.replace(/[^0-9]/, '').replace(/^0+/, ''),
                  )
                }}
                onFocus={() => {}}
              />
            )}
            control={control}
          />
          <div className={styles.buttonsContainer}>
            {auth.isAuthenticated || (
              <CheckboxInput
                checked={areRegulationsConfirmed}
                onChange={() =>
                  setAreRegulationsConfirmed(!areRegulationsConfirmed)
                }
                content={<ObligatoryRegulationsText />}
                name="acceptRegulationsCheck"
              />
            )}
            <Button
              optionalStyles="my-1"
              type="submit"
              disabled={isPaymentLoading || !areRegulationsConfirmed}>
              Przekaż datek
            </Button>
          </div>
        </form>
      ) : (
        <>
          {[10, 20, 50].map((value) => (
            <Link
              to={`/choose-payment-method/${parishId}/${goalId}/${value}`}
              key={value}>
              <Button
                onButtonClick={() => {
                  GAEvent(GACategoryName, `Wpłata ${value} zł`, value)
                }}
                optionalStyles="my-1">{`${value} PLN`}</Button>
            </Link>
          ))}
        </>
      )}
      <Button
        onButtonClick={toggleCustomDonation}
        buttonStyle="underlined"
        optionalStyles="mb-3 mt">
        {customDonation ? 'Wróć' : 'Inna kwota'}
      </Button>
    </div>
  )
}
