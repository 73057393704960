import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useGetUrlParams = () => {
  const searchParams = useLocation().search
  const queryParams = useMemo(() => new URLSearchParams(searchParams), [
    searchParams,
  ])
  return queryParams
}
