import { FC } from 'react'
import { AuthProvider } from './AuthProvider'
import { QueryClient, QueryClientProvider } from 'react-query'
import { persistWithLocalStorage } from 'react-query/persist-localstorage-experimental'

const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         cacheTime: Infinity
      }
   }
})

persistWithLocalStorage(queryClient)

export const Providers: FC = ({ children }) => {

   return (
     <QueryClientProvider client={queryClient}>
        <AuthProvider>
           {children}
        </AuthProvider>
     </QueryClientProvider>
   )
}
