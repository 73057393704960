import { FC } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { ReactComponent as PhoneIcon } from 'assets/phone.svg'
import { ReactComponent as EmailIcon } from 'assets/email.svg'
import { Button, CollapsibleCard, Loader, TextWithIcon } from 'components'
import { useFormattedIbanNumber } from 'hooks'
import { fetchParishDetails, fetchParishGoals } from 'services'
import { GAEvent } from 'utils/googleAnalyticsUtils'
import { responseDataSelector } from 'utils'

interface Props {
  parishId: string
}

export const ParishDetailsCard: FC<Props> = ({ parishId }) => {
  const {
    data: parishDetails,
    error: parishDetailsError,
    isLoading: parishDetailsIsLoading,
    isLoadingError: parishDetailsIsLoadingError,
  } = useQuery(
    ['parishDetailsCard', parishId],
    () => fetchParishDetails(parishId),
    { select: responseDataSelector },
  )

  const { isSuccess: goalsIsSuccess, data: goals } = useQuery(
    ['parishGoals', parishId],
    () => fetchParishGoals(parishId),
    {
      select: responseDataSelector,
    },
  )

  const areGoalsAvailable = goalsIsSuccess && goals.length > 0

  const history = useHistory()
  const formattedIbanNumber = useFormattedIbanNumber(parishDetails?.iban)

  return (
    <div className="flex flex-col items-center my-4 p-4 pb-1 w-full rounded-3xl shadow-custom">
      {parishDetailsIsLoading && !parishDetailsIsLoadingError ? (
        <Loader />
      ) : (
        parishDetails && (
          <CollapsibleCard
            title={
              <div className="flex flex-col">
                <p className="text-lg font-bold">{parishDetails.name}</p>
                <p className="text-md font-medium">{parishDetails.address}</p>
              </div>
            }
            photoPathName={parishDetails.image_path}
            actionButton={
              areGoalsAvailable && (
                <Button
                  buttonStyle="underlined"
                  onButtonClick={() => {
                    GAEvent('Parafia', 'Przejście do widoku zbiórek parafii')
                    history.push(`/parishes/${parishId}/goals`)
                  }}>
                  Sprawdź trwające zbiórki
                </Button>
              )
            }
            GACategoryName="Parafia">
            <div className="flex flex-col items-center mt-4 px-1 w-full">
              <p className="text-center text-md font-medium">
                {parishDetails.description}
              </p>
              <div className="my-4 w-full h-px bg-primary opacity-50" />
              <span className="mb-2 text-primary text-md font-semibold">
                Proboszcz parafii:
              </span>
              <span className="mb-2 text-md font-semibold">
                {parishDetails.preacher}
              </span>
              <span className="mb-2 text-primary text-md font-semibold">
                Numer konta parafii:
              </span>
              <span className="text-md font-semibold">
                {formattedIbanNumber}
              </span>
              <Button
                buttonStyle="underlined"
                fontSize="md"
                onButtonClick={() =>
                  window.open(
                    `https://api.stage.mkosciol.binar.app/api/v1/parishes/${parishId}/blue_media_regulations`,
                    '_blank',
                  )
                }>
                Regulamin operatora płatności
              </Button>
              <div className="my-2 w-full h-px bg-primary opacity-50" />
              <TextWithIcon icon={<EmailIcon />}>
                {parishDetails.email_address}
              </TextWithIcon>
              <TextWithIcon icon={<PhoneIcon />}>
                {parishDetails.phone_number}
              </TextWithIcon>
            </div>
          </CollapsibleCard>
        )
      )}
      {parishDetailsIsLoadingError && (
        <p>{`Wystąpił błąd podczas ładowania danych, ${parishDetailsError}`}</p>
      )}
    </div>
  )
}
