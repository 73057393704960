import { FC } from 'react'

export const ObligatoryRegulationsText: FC = () => (
  <span className="text-sm">
    Akceptuję{' '}
    <a
      className="underline"
      href="https://api.stage.mkosciol.binar.app/regulation.pdf"
      target="_blank"
      rel="noreferrer">
      regulamin
    </a>{' '}
    i{' '}
    <a
      className="underline"
      href="https://api.stage.mkosciol.binar.app/regulation.pdf"
      target="_blank"
      rel="noreferrer">
      politykę prywatności
    </a>{' '}
    (wymagane)
  </span>
)
