import { useLayoutEffect, useState } from 'react'

export const useGetWindowHeight = () => {
  const [height, setHeight] = useState(0)
  useLayoutEffect(() => {
    setHeight(window.innerHeight)
  }, [])

  return height
}
