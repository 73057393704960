import React, { forwardRef } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Button } from 'components'
import styles from './BottomModal.module.css'
import { useAuth } from 'hooks'
import { GAEvent } from 'utils/googleAnalyticsUtils'

export const BottomModal = forwardRef<HTMLDivElement>((_, ref) => {
  const history = useHistory()
  const auth = useAuth()
  const location = useLocation()
  return auth.isAuthenticated ? (
    <></>
  ) : (
    <div className={styles.buttonsContainer} ref={ref}>
      <Button
        onButtonClick={() => {
          GAEvent('Main', 'Przejście do widoku logowania')
          history.push('./login', { from: location.pathname })
        }}
        buttonStyle="secondary">
        Zaloguj
      </Button>
      <div className="pb-2" />
      <Button
        onButtonClick={() => GAEvent('Main', 'Przejście do widoku rejestracji')}
        buttonStyle="underlined">
        <Link to="/registration">Przejdź do rejestracji</Link>
      </Button>
    </div>
  )
})
