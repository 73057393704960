import React from 'react'
import { ParishesCardList, BottomModal, AppHeaderWithMenu } from 'components'
import { useComponentHeight } from 'hooks'

export const ParishesScreen = () => {
  const { height, ref } = useComponentHeight()

  return (
    <AppHeaderWithMenu optionalStyles={{ marginBottom: height }} hideLeftIcon>
      <ParishesCardList />
      <BottomModal ref={ref} />
    </AppHeaderWithMenu>
  )
}
