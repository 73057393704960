import { forwardRef, InputHTMLAttributes } from 'react'
import styles from './CheckboxInput.module.css'
import { ReactComponent as CheckBox } from 'assets/checkbox.svg'
import { ReactComponent as CheckBoxError } from 'assets/checkbox-error.svg'
import { ReactComponent as CheckBoxChecked } from 'assets/checkbox-checked.svg'

export type CheckboxInputProps = {
  content: string | JSX.Element
  error?: boolean
  checked: boolean
  name: string
  onChange(e: InputHTMLAttributes<HTMLInputElement>): void
}
export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  ({ content, checked, onChange, error = false, name }, ref) => {
    return (
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className={styles.input}
          ref={ref}
          name={name}
        />
        <div className={styles.container}>
          <div className={styles.checkboxContainer}>
            {checked ? (
              <CheckBoxChecked className={styles.checkboxIcon} />
            ) : error ? (
              <CheckBoxError className={styles.checkboxIcon} />
            ) : (
              <CheckBox className={styles.checkboxIcon} />
            )}
          </div>

          {typeof content === 'string' ? (
            <span className="text-sm">{content}</span>
          ) : (
            <div className="self-center">{content}</div>
          )}
        </div>
      </label>
    )
  },
)
