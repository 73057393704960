import { FC } from 'react'
import { PaymentHistoryRecord } from 'components/PaymentHistoryList/types'
import { format } from 'date-fns'
import { PaymentStatus } from 'components/PaymentStatus/PaymentStatus'

type Props = {
  payment: PaymentHistoryRecord
}

export const PaymentHistoryCard: FC<Props> = ({ payment }) => {
  return (
    <>
      <div className="flex justify-between text-md font-medium">
        {payment.payment_date && (
          <>
            <span>
              {format(Date.parse(payment.payment_date), 'dd.MM.yyyy')}
            </span>
            <span>{format(Date.parse(payment.payment_date), 'HH:mm')}</span>
          </>
        )}
      </div>
      <div className="flex flex-col items-center justify-between my-4 p-4 w-full min-h-xs rounded-3xl shadow-custom">
        <div className="flex flex-col items-center w-full">
          <span className="text-center text-primary text-xl font-bold">
            {payment.parish_name}
          </span>
          <span className="text-center text-md font-medium">
            {payment.goal_name}
          </span>
        </div>
        <div className="flex flex-col items-center w-full">
          <div className="mx-6 my-3 w-full h-px border-t border-black border-opacity-10" />
          <span className="text-center text-md font-medium">
            Wysokość datku:
          </span>
          <span className="text-center text-lg font-bold">
            {`${payment.amount / 100} ${payment.amount_currency}`}
          </span>
          <div className="mt-3">
            <PaymentStatus status={payment.status} />
          </div>
        </div>
      </div>
    </>
  )
}
