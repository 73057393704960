import { useMemo } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { getMenuItems } from 'utils'
import { useAuth } from 'hooks'
import { version } from '../../../package.json'
import styles from './AppMenu.module.css'
import clsx from 'clsx'

type AppMenuProps = {
  toggleMenu(): void
  menuOn: boolean
}

export const AppMenu = ({ menuOn, toggleMenu }: AppMenuProps) => {
  const isPoC = true
  const { isAuthenticated, signOut } = useAuth()
  const history = useHistory()
  const location = useLocation()

  const menuItems = useMemo(() => getMenuItems(!!isAuthenticated), [
    isAuthenticated,
  ])

  return (
    <div className={clsx(styles.menuContainer, menuOn && styles.menuOn)}>
      <div className={styles.menuItemsContainer}>
        {!isAuthenticated && (
          <div
            className={styles.menuItem}
            onClick={() => {
              toggleMenu()
              history.push('/login', { from: location.pathname })
            }}>
            Zaloguj się
          </div>
        )}
        {menuItems.map(({ pathName, name, hidden, isExternal }, index) => {
          return pathName && !hidden ? (
            <div key={name} className={styles.menuItem}>
              {isExternal ? (
                <a
                  href="https://api.stage.mkosciol.binar.app/regulation.pdf"
                  target="_blank"
                  rel="noreferrer">
                  {name}
                </a>
              ) : (
                <Link to={`/${pathName}`}>{name}</Link>
              )}
            </div>
          ) : (
            !hidden && <div key={`menuItem-${index}`} />
          )
        })}
        {isAuthenticated && (
          <div
            className={clsx(styles.menuItem, isPoC && 'order-first')}
            onClick={() => {
              signOut()
              toggleMenu()
              history.push('/parishes')
            }}>
            Wyloguj
          </div>
        )}
      </div>
      <p className={styles.versionText}>Wersja aplikacji v.{version}</p>
    </div>
  )
}
