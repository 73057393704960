import { FC } from 'react';
import { ReactComponent as LoaderSvg } from 'assets/loader.svg';
type LoaderProps = {
  size?: number;
};

export const Loader: FC<LoaderProps> = ({ size = 24 }) => {
  return (
    <div className="flex justify-center max-h-full">
      <LoaderSvg height={size} />
    </div>
  );
};
