const commonMenuItems = [
  { name: 'O twórcach', pathName: 'about', hidden: true, isExternal: false },
  { name: 'Kontakt', pathName: 'contact', hidden: true, isExternal: false },
  { name: 'Regulamin', pathName: '/', hidden: false, isExternal: true },
  {
    name: 'Polityka prywatności',
    pathName: '/',
    hidden: false,
    isExternal: true,
  },
]

const loggedInMenuItems = [
  {
    name: 'Dane użytkownika',
    pathName: 'personal-data',
    hidden: true,
    isExternal: false,
  },
  {
    name: 'Twoje datki',
    pathName: 'user/payment-history',
    hidden: false,
    isExternal: false,
  },
  { name: '', pathName: '', hidden: false, isExternal: false },
  ...commonMenuItems,
]

const notLoggedInMenuItems = [
  {
    name: 'Zarejestruj się',
    pathName: 'registration',
    hidden: false,
    isExternal: false,
  },
  ...commonMenuItems,
]
export const getMenuItems = (isLogged: boolean) =>
  isLogged ? loggedInMenuItems : notLoggedInMenuItems
