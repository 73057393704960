import { useMemo, forwardRef, InputHTMLAttributes } from 'react'
import clsx from 'clsx'
import { ReactComponent as VisibilityOnIcon } from 'assets/visibility_on.svg'
import { ReactComponent as VisibilityOffIcon } from 'assets/visibility_off.svg'
import { useToggle } from 'hooks'
import styles from './TextInput.module.css'

export type TextInputProps = {
  label: string
  hideLabel?: boolean
  placeholder: string
  type: 'text' | 'password' | 'email' | 'number'
  inputMode?: 'numeric' | undefined
  name: string
  error?: string
} & InputHTMLAttributes<HTMLInputElement>

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    { label, error = '', type = 'text', hideLabel = false, inputMode, ...rest },
    ref,
  ) => {
    const { on: showPassword, toggle: toggleShowPassword } = useToggle()

    const typeToShow = useMemo(() => {
      return type === 'password' ? (showPassword ? 'text' : 'password') : type
    }, [type, showPassword])

    return (
      <div className={styles.container}>
        <label
          className={clsx(styles.label, hideLabel && styles.hidden)}
          htmlFor={label}>
          {label}
        </label>
        <div
          className={clsx(
            styles.inputContainer,
            error && styles.errorContainer,
          )}>
          <input
            className={styles.input}
            id={label}
            onFocus={(e) => (e.target.placeholder = '')}
            onBlur={(e) => (e.target.placeholder = rest.placeholder)}
            type={typeToShow}
            ref={ref}
            inputMode={inputMode}
            {...rest}
          />
          {type === 'password' &&
            (showPassword ? (
              <VisibilityOnIcon
                className={styles.icon}
                onClick={toggleShowPassword}
              />
            ) : (
              <VisibilityOffIcon
                className={styles.icon}
                onClick={toggleShowPassword}
              />
            ))}
          {inputMode === 'numeric' && (
            <p className={styles.currencyName}>PLN</p>
          )}
        </div>
        {error && <p className={styles.error}>{error}</p>}
      </div>
    )
  },
)
