import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { logout } from 'services'

export interface AuthContext {
  signOut(): void
  signIn(token: string): void
  isAuthenticated: boolean | null
}

export const useProvideAuth = (): AuthContext => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null)
  const token = localStorage.getItem('token') as string

  const mutation = useMutation(() => logout(), {
    onSuccess: () => {
      localStorage.removeItem('token')
      setIsAuthenticated(false)
    },
  })

  useEffect(() => {
    setIsAuthenticated(!!token)
  }, [token])

  const signIn = (token: string) => {
    localStorage.setItem('token', token)
    setIsAuthenticated(true)
  }

  const signOut = () => mutation.mutate()

  return {
    signIn,
    signOut,
    isAuthenticated,
  }
}
