import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import styles from './AppHeader.module.css'
import { ReactComponent as LogoIcon } from 'assets/logo.svg'
import { ReactComponent as ArrowIcon } from 'assets/arrow_back.svg'

export type AppHeaderProps = {
  hideLeftIcon?: boolean
  rightIcon: JSX.Element
  background?: boolean
}

export const AppHeader: FC<AppHeaderProps> = ({
  hideLeftIcon = false,
  background = true,
  rightIcon,
}) => {
  const history = useHistory()
  return (
    <>
      {background ? (
        <div className={styles.backgroundContainer} />
      ) : (
        <div className={styles.noBackground} />
      )}
      <div className={styles.iconsContainer}>
        <ArrowIcon
          width="24"
          height="24"
          onClick={() => {
            history.goBack()
          }}
          className={clsx(styles.icon, hideLeftIcon && styles.hideIcon)}
        />
        <LogoIcon height="32px" width="32px" />
        {rightIcon}
      </div>
    </>
  )
}
