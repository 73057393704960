import { FC } from 'react';
import clsx from 'clsx';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import { ReactComponent as InfoErrorIcon } from 'assets/infoError.svg';
import styles from './InfoElement.module.css';

export type InfoElementProps = {
  error?: boolean;
  content: string;
};

export const InfoElement: FC<InfoElementProps> = ({
  error = false,
  content,
}) => {
  return (
    <div className={styles.container}>
      <div>
        {error? <InfoErrorIcon className={styles.icon} /> : <InfoIcon className={styles.icon}/>}
      </div>
      <p className={clsx(styles.text, error && styles.error)}>{content}</p>
    </div>
  );
};
