import { useEffect } from 'react'
import ReactGA from 'react-ga'

const trackingID = process.env.REACT_APP_GA_ID

export const useInitializeGA = () => {
  useEffect(() => {
    if (trackingID) {
      ReactGA.initialize(trackingID, {
        titleCase: false,
      })
    }
  }, [])
}
