import { Route, Redirect } from 'react-router-dom'
import { useAuth } from 'hooks'
import { FC } from 'react'
import { Loader } from '../Loader/Loader'

interface PrivateRouteProps {
   path: string
   exact: boolean
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ children, ...rest }) => {
   let { isAuthenticated } = useAuth();

   if (isAuthenticated === null) {
      return <Loader/>
   }

   return (
     <Route
       render={({ location }) =>
         isAuthenticated ? (
           children
         ) : (
           <Redirect
             to={{
                pathname: "/login",
                state: { from: location }
             }}
           />
         )
       }
       {...rest}
     />
   );
}
