import bread from 'assets/images/bread.jpg'
import moon from 'assets/images/moon.jpg'
import prayer from 'assets/images/prayer.jpg'
import prayer2 from 'assets/images/prayer-2.jpg'
import { useEffect, useState } from 'react'
import { getRandomArrayElement } from '../utils/getRandomArrayElement'

export const useRandomPhoto = () => {
  const [randomPhoto, setRandomPhoto] = useState<string>('')

  useEffect(() => {
    setRandomPhoto(getRandomArrayElement([bread, moon, prayer, prayer2]))
  }, [])

  return randomPhoto
}
