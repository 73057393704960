import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Loader, ParishCard, GoalsList, AppHeaderWithMenu } from 'components'
import { fetchParishDetails } from 'services'
import { responseDataSelector } from 'utils'

type ParishGoalsParamsProps = {
  id: string
}
export const ParishGoals: FC = () => {
  const { id: parishId } = useParams<ParishGoalsParamsProps>()

  const { data: parishDetails, isLoading, isLoadingError } = useQuery(
    ['parishDetails/ParishGoals', parishId],
    () => fetchParishDetails(parishId),
    { select: responseDataSelector },
  )

  return (
    <AppHeaderWithMenu>
      {isLoading && !isLoadingError ? (
        <Loader />
      ) : (
        parishDetails && <ParishCard {...parishDetails} />
      )}
      <h2 className="text-md font-semibold">Obecnie trwające zbiórki</h2>
      <GoalsList parishId={parishId} />
    </AppHeaderWithMenu>
  )
}
