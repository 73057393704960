import { AxiosResponse } from 'axios'
import { useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'
import { GoalDetailsProps } from 'screens'
import { fetchParishDetails, fetchParishGoal } from 'services'
import { responseDataSelector } from 'utils'

type ParishOrGoalProps = {
  goalId: string | null
  parishId: string
  readyToFetchData: boolean
}

type ParishOrGoal = {
  name: string | undefined
  isLoading: boolean
  isLoadingError: boolean
  error: unknown
  isSuccess: boolean
}
export const useFetchParishOrGoalDetails = ({
  parishId,
  goalId,
  readyToFetchData,
}: ParishOrGoalProps): ParishOrGoal => {
  const donateChurch = useMemo(() => parishId === goalId, [parishId, goalId])

  const showDescription = useCallback(
    (goalDetails: GoalDetailsProps) => {
      return donateChurch ? goalDetails?.name : goalDetails?.description
    },
    [donateChurch],
  )

  const fetchParishDetailsOrParishGoal = useCallback(
    (parishId: string, goalId: string): Promise<AxiosResponse> => {
      return donateChurch
        ? fetchParishDetails(parishId)
        : fetchParishGoal(parishId, goalId)
    },
    [donateChurch],
  )

  const {
    data: goalDetails,
    error,
    isLoading,
    isLoadingError,
    isSuccess,
  } = useQuery(
    ['/goalDetails', parishId, goalId],
    () => fetchParishDetailsOrParishGoal(parishId, goalId ? goalId : parishId),
    {
      select: responseDataSelector,
      enabled: readyToFetchData,
    },
  )

  return {
    name: showDescription(goalDetails),
    isLoading,
    isLoadingError,
    error,
    isSuccess,
  }
}
