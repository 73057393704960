import React, { FC } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { Button, Feedback, LoaderWithLogo } from 'components'
import { verifyAccount } from 'services'
import { responseDataSelector } from 'utils'
import { accountVerifyContent, accountVerifyTitle } from 'constants/index'
import { useGetUrlParams, useWait } from 'hooks'
import { ReactComponent as RegistrationCompleteIcon } from 'assets/emailSent.svg'
import { ReactComponent as ErrorIcon } from 'assets/error.svg'

export const AccountVerifyScreen: FC = () => {
  const history = useHistory()
  const query = useGetUrlParams()
  const confirmation_token = query.get('confirmation_token')

  const ready = useWait(1500)

  const {
    isLoading: isLConfirmationLoading,
    isError: isConfirmationError,
  } = useQuery('verifyAccount', () => verifyAccount(confirmation_token), {
    select: responseDataSelector,
    retry: false,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: 0,
  })

  const isLoading = !ready || isLConfirmationLoading
  const isSuccess = ready && !isLConfirmationLoading && !isConfirmationError
  const isError = ready && !isLConfirmationLoading && isConfirmationError

  if (isLoading) {
    return (
      <Feedback
        title={accountVerifyTitle['processing']}
        content={accountVerifyContent['processing']}>
        <LoaderWithLogo />
      </Feedback>
    )
  }
  if (isSuccess) {
    return (
      <Feedback
        title={accountVerifyTitle['success']}
        content={accountVerifyContent['success']}>
        <RegistrationCompleteIcon />
        <Button
          onButtonClick={() => history.push('/login')}
          optionalStyles="mt-8">
          Przejdź do logowania
        </Button>
      </Feedback>
    )
  }
  if (isError) {
    return (
      <Feedback
        title={accountVerifyTitle['error']}
        content={accountVerifyContent['errorFirstLine']}
        extraContent={accountVerifyContent['errorSecondLine']}
      >
        <ErrorIcon />
      </Feedback>
    )
  }

  return null
}
