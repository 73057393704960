import { api } from './api'

export const fetchParishes = () => api.get('/parishes')

export const fetchParishDetails = (parishId: string) =>
  api.get(`parishes/${parishId}`)

export const fetchParishGoals = (parishId: string) =>
  api.get(`parishes/${parishId}/goals`)

export const fetchParishGoal = (parishId: string, goalId: string) =>
  api.get(`parishes/${parishId}/goals/${goalId}`)
