import { FC } from 'react'
import { Goal } from '../types'
import { Button, CollapsibleCard } from 'components'
import { useHistory } from 'react-router-dom'
import { GAEvent } from 'utils/googleAnalyticsUtils'
import { useFormattedIbanNumber } from 'hooks'

export interface GoalComponentProps {
  goal: Goal
}

export const GoalComponent: FC<GoalComponentProps> = ({ goal }) => {
  const history = useHistory()
  const formattedIbanNumber = useFormattedIbanNumber(goal.iban_number)

  return (
    <div className="flex flex-col my-4 p-4 pb-1 w-full rounded-3xl shadow-custom">
      <CollapsibleCard
        title={<p className="text-md font-bold">{goal.name}</p>}
        actionButton={
          <Button
            onButtonClick={() => {
              GAEvent('Zbiórki', 'Przejście do widoku szczegółów celu')
              history.push(`/parishes/${goal.parish_id}/goals/${goal.id}`)
            }}
            optionalStyles="my-4">
            Wspomóż cel
          </Button>
        }
        GACategoryName="Zbiórki">
        <div className="mt-4">
          <p className="text-center text-md font-medium">{goal.description}</p>
          <div className="flex flex-col items-center mt-4">
            <span className="mb-2 text-primary text-md font-semibold">
              Numer konta zbiórki:
            </span>
            <span className="text-md font-semibold">{formattedIbanNumber}</span>
            <div className="my-2 w-full h-px bg-primary opacity-50" />
          </div>
        </div>
      </CollapsibleCard>
    </div>
  )
}
