import { ReactComponent as Loader } from 'assets/blue_loader.svg'
import { ReactComponent as Logo } from 'assets/logo.svg'
export const LoaderWithLogo = () => {
  return (
    <div className="relative flex items-center justify-center">
      <Loader className="animate-spin-slow" />
      <Logo
        className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
        width="50px"
        height="50px"
      />
    </div>
  )
}
