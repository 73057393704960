import clsx from 'clsx'
import { FC } from 'react'
import styles from './Button.module.css'

export interface ButtonProps {
  children: string | JSX.Element

  onButtonClick?(): void

  buttonStyle?: 'primary' | 'secondary' | 'underlined'
  optionalStyles?: string
  type?: 'submit' | 'button'
  disabled?: boolean
  fontSize?: 'sm' | 'md' | 'lg' | 'xl'
}

export const Button: FC<ButtonProps> = ({
  children,
  onButtonClick,
  buttonStyle = 'primary',
  optionalStyles,
  type = 'button',
  disabled = false,
  fontSize = 'lg',
  ...rest
}) => {
  return (
    <button
      className={clsx(
        styles.base,
        styles[buttonStyle],
        `text-${fontSize}`,
        optionalStyles,
        disabled && styles.disabled,
      )}
      disabled={disabled}
      onClick={onButtonClick}
      {...rest}>
      {children}
    </button>
  )
}
