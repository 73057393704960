import { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Feedback } from 'components'
import { ReactComponent as MailSentIcon } from 'assets/emailSent.svg'
import { ReactComponent as CloseIcon } from 'assets/close.svg'

export const RegistrationMailSentScreen: FC = ({ children }) => {
  const history = useHistory()

  return (
    <div className="relative">
      <CloseIcon
        className="fixed right-8 top-8"
        onClick={() => history.replace('/parishes')}
      />
      <Feedback
        title={'Gratulacje!'}
        content={`Na Twój adres e-mail został wysłany link aktywacyjny,
        `}
        extraContent="sprawdź skrzynkę odbiorczą">
         
        <MailSentIcon />
        {children}
      </Feedback>
    </div>
  )
}
