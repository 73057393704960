import { FC } from 'react'

export interface TextWithIconProps {
   icon: JSX.Element
   iconReversed?: boolean,
   children: string;
}

export const TextWithIcon: FC<TextWithIconProps> = ({
   icon,
   children,
   iconReversed = false
}) => <div className={`flex items-center my-2 ${iconReversed && 'flex-row-reverse'}`}>
   <div className="mx-2 h-6 w-6">{icon}</div>
   <span className="font-semibold text-md">{children}</span>
</div>
