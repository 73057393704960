import { FC } from 'react'
import styles from './CollapsibleCard.module.css'
import clsx from 'clsx'
import useCollapse from 'react-collapsed'
import { ReactComponent as DropDownArrowIcon } from 'assets/dropdown_arrow.svg'
import { useRandomPhoto } from 'hooks/useRandomPhoto'
import { GAEvent } from 'utils/googleAnalyticsUtils'

export interface CollapsibleCardProps {
  title: string | JSX.Element
  photoPathName?: string
  actionButton?: JSX.Element | boolean
  children: JSX.Element | string
  GACategoryName?: string
}

export const CollapsibleCard: FC<CollapsibleCardProps> = ({
  title,
  children,
  photoPathName,
  actionButton,
  GACategoryName = 'Global',
}) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const randomPhoto = useRandomPhoto()

  // Temporary solution for PoC ,eventually all photos will be delivered through api
  const cardPhotoUrl = photoPathName
    ? `https://api.stage.mkosciol.binar.app/${photoPathName}`
    : randomPhoto

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex items-center justify-between w-full">
        {title}
        <div
          onClick={() =>
            !isExpanded && GAEvent(GACategoryName, 'Rozwinięcie szczegółów')
          }>
          <button className={styles.dropdownButton} {...getToggleProps()}>
            <DropDownArrowIcon
              className={clsx(
                'transform duration-300',
                isExpanded && '-rotate-180',
              )}
              height="24px"
              width="24px"
            />
          </button>
        </div>
      </div>
      <div
        className={clsx(
          'mt-4 w-full h-28 bg-cover bg-center bg-no-repeat rounded-2xl duration-300',
          isExpanded && 'h-48',
        )}
        style={{
          backgroundImage: `url(${cardPhotoUrl})`,
        }}
      />
      <div
        className={clsx(styles.text, isExpanded && 'animate-fadeIn')}
        {...getCollapseProps()}>
        <div>{children}</div>
      </div>
      {actionButton || <div className="mt-3" />}
    </div>
  )
}
