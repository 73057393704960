import { api } from './api'
import { RegisterProps, UserLogin } from './types'

export const login = (user: UserLogin) =>
  api.post('/users/sign_in', { user: { ...user } })
export const logout = () => api.delete('/users/sign_out')

export const registerNewUser = (props: RegisterProps) =>
  api.post(
    '/users',
    {
      user: {
        email: props.email,
        password: props.password,
        password_confirmation: props.password,
        marketing_regulations: props.marketingAgreementCheck,
      },
    },
    { headers: { 'Content-type': 'application/json' } },
  )

export const verifyAccount = async (confirmation_token: string | null) => {
  if (confirmation_token === null) {
    throw new Error('Confirmation token missing')
  }
  return await api.get(
    `/users/confirmation?confirmation_token=${confirmation_token}`,
  )
}

export const fetchCurrentUser = () => api.get('/current_user')
