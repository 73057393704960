import { useState, useEffect } from 'react'

export const useWait = (delayInMs = 1000) => {
  const [ready, setReady] = useState(false)
  useEffect(() => {
    setReady(false)
    const id = setTimeout(() => {
      setReady(true)
    }, delayInMs)
    return () => clearTimeout(id)
  }, [delayInMs])
  return ready
}
