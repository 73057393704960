import { FC } from 'react'
import { PaymentHistoryCard } from 'components'
import { PaymentHistoryRecord } from './types'

type Props = {
  paymentsList: PaymentHistoryRecord[]
}

export const PaymentHistoryList: FC<Props> = ({ paymentsList }) => {
  return (
    <div className="flex flex-col">
      {paymentsList.length === 0 ? (
        <p className="text-center">Nie masz jeszcze żadnych transakcji</p>
      ) : (
        paymentsList.map((payment: PaymentHistoryRecord, index) => (
          <div
            key={`${payment.payment_date} ${payment.parish_name} ${index}`}
            className="first:mt-0 mt-6">
            <PaymentHistoryCard payment={payment} />
            {paymentsList[index + 1] && (
              <div className="mx-6 my-3 h-px border-t border-black border-opacity-10" />
            )}
          </div>
        ))
      )}
    </div>
  )
}
