import { FC } from 'react'
import { AppHeaderWithMenu, Loader, PaymentHistoryList } from 'components'
import styles from './PaymentHistory.module.css'
import { useQuery } from 'react-query'
import { fetchCurrentUser } from 'services'
import { responseDataSelector } from 'utils'

export const PaymentHistory: FC = () => {
  const { isSuccess, isLoading, isError, data, error } = useQuery(
    'currentUser',
    () => fetchCurrentUser(),
    {
      select: responseDataSelector,
      refetchInterval: 10000,
    },
  )

  return (
    <AppHeaderWithMenu>
      <div className={styles.contentContainer}>
        {isLoading && !isError ? (
          <Loader />
        ) : (
          isSuccess && <PaymentHistoryList paymentsList={data.user_payments} />
        )}
        {isError && (
          <p className="self-center text-center">{`Wystąpił błąd podczas ładowania danych, ${error}`}</p>
        )}
      </div>
    </AppHeaderWithMenu>
  )
}
