import { ChangeEvent, useState } from 'react'
import { useMutation } from 'react-query'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import styles from './RegistrationScreen.module.css'
import {
  TextInput,
  InfoElement,
  CheckboxInput,
  Button,
  FormHeader,
  ObligatoryRegulationsText,
} from 'components'
import { emailRegExp, passRegExp } from 'constants/index'
import { registerNewUser, RegisterProps } from 'services'
import { ResponseError, ResponseProps } from './types'
import { GAEvent } from 'utils/googleAnalyticsUtils'

export const RegistrationScreen = () => {
  const [responseError, setResponseError] = useState('')
  const history = useHistory()
  const { register, errors, handleSubmit, control } = useForm({
    mode: 'onTouched',
  })

  const mutation = useMutation(
    (formData: RegisterProps) => registerNewUser(formData),
    {
      onSuccess: (response: ResponseProps) => {
        GAEvent(
          'Rejestracja',
          'Zarejestrowano bez weryfikacji',
          undefined,
          true,
        )
        history.push(`/registerMail/?email=${response.data.data.email}`)
      },
      onError: (err: ResponseError) => {
        setResponseError(
          err?.response?.data.errors[0].details ||
            'Rejestracja nie powiodła się',
        )
      },
    },
  )

  const onSubmit = async (formData: RegisterProps) => {
    delete formData.regulationsAndPrivacyPolicyCheck
    GAEvent('Rejestracja', 'Wciśnięto przycisk rejestracji')
    mutation.mutate(formData)
  }

  return (
    <div className="flex flex-col flex-shrink">
      <FormHeader
        title="Zarejestruj się w aplikacji"
        subtitle="I wspieraj swoją parafię kiedy tylko chcesz"
      />
      <form
        className={styles.form}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        onChange={() => {
          setResponseError('')
        }}>
        <div className={styles.inputContainer}>
          <TextInput
            label="E-mail"
            placeholder="Wpisz adres e-mail"
            type="email"
            name="email"
            defaultValue=""
            ref={register({
              required: true,
              pattern: emailRegExp,
            })}
            error={errors.email && 'Niepoprawny adres email'}
          />
          <TextInput
            placeholder="Podaj hasło"
            label="Ustaw hasło"
            type="password"
            name="password"
            defaultValue=""
            ref={register({
              required: true,
              pattern: passRegExp,
            })}
            error={errors.password && 'Nieprawidłowe hasło'}
          />
          <div className={styles.infoContainer}>
            {responseError ? (
              <InfoElement content={responseError} error />
            ) : (
              <InfoElement
                content="Hasło powinno składać się z 1 dużej litery, 1 znaku specjalnego i conajmniej 8 znaków"
                error={!!errors.password}
              />
            )}
          </div>
          <div className="flex flex-col">
            <Controller
              control={control}
              defaultValue={false}
              name="regulationsAndPrivacyPolicyCheck"
              rules={{ required: true }}
              render={({ value, onChange, name, ref }) => {
                return (
                  <CheckboxInput
                    checked={value}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onChange(e.target.checked)
                    }
                    content={<ObligatoryRegulationsText />}
                    name={name}
                    ref={ref}
                    error={errors.regulationsAndPrivacyPolicyCheck}
                  />
                )
              }}
            />
            <Controller
              control={control}
              defaultValue={false}
              name="marketingAgreementCheck"
              rules={{ required: false }}
              render={({ value, onChange, name, ref }) => {
                return (
                  <CheckboxInput
                    checked={value}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      onChange(e.target.checked)
                    }
                    content={
                      <span className="text-sm">
                        Akceptuję{' '}
                        <a
                          className="underline"
                          href="https://api.stage.mkosciol.binar.app/regulation.pdf"
                          target="_blank"
                          rel="noreferrer">
                          zgodę marketingową
                        </a>{' '}
                        (opcjonalnie)
                      </span>
                    }
                    name={name}
                    ref={ref}
                    error={errors.marketingAgreementCheck}
                  />
                )
              }}
            />
          </div>
          <Button type="submit" disabled={mutation.isLoading}>
            Zarejestruj się
          </Button>
        </div>
      </form>
    </div>
  )
}
