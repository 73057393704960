import ReactGA from 'react-ga'

export const GAEvent = (
  categoryName: string,
  eventName: string,
  value?: number,
  nonInteraction = false,
) => {
  ReactGA.event({
    category: categoryName,
    action: eventName,
    value: value,
    nonInteraction: nonInteraction,
  })
}
