import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { AppHeaderWithMenu, ChooseDonationButtons } from 'components'
import { useToggle } from 'hooks'
import styles from './GoalDetails.module.css'
import { GoalDetailsCard } from 'components'

type ParamProps = {
  parish_id: string
  id: string
}

export const GoalDetails: FC = () => {
  const { parish_id: parishId, id: goalId } = useParams<ParamProps>()
  const { on: customDonation, toggle: toggleCustomDonation } = useToggle()

  return (
    <AppHeaderWithMenu>
      <div className={styles.contentContainer}>
        <GoalDetailsCard parishId={parishId} goalId={goalId} />
        <ChooseDonationButtons
          parishId={parishId}
          goalId={goalId}
          customDonation={customDonation}
          toggleCustomDonation={toggleCustomDonation}
          GACategoryName="Zbiórki"
        />
      </div>
    </AppHeaderWithMenu>
  )
}
