import { FC, useState } from 'react'
import { useQuery } from 'react-query'
import { ReactComponent as PaymentDoneIcon } from 'assets/paymentDone.svg'
import { ReactComponent as ErrorIcon } from 'assets/error.svg'
import { Button, Feedback, LoaderWithLogo } from 'components'
import { useFetchParishOrGoalDetails, useGetUrlParams, useWait } from 'hooks'
import { getPaymentDetails, verifyPayment } from 'services/paymentService'
import { responseDataSelector } from 'utils'

type PaymentDetailsResponseType = {
  amount: {
    currency_iso: {
      iso_code: string
      decimal_mark: string
    }
    value: string
  }
  parish_id: string
  goal_id: string
  status: 'pending' | 'success' | 'failure'
}

export const PaymentReturnScreen: FC = () => {
  const params = useGetUrlParams()
  const serviceId = params.get('ServiceID') as string
  const orderId = params.get('OrderID') as string
  const hash = params.get('Hash') as string
  const [shouldRefetch, setShouldRefetch] = useState<Boolean>(true)

  const ready = useWait(1500)

  const {
    isLoading: isLoadingVerify,
    isError: isVerifyError,
    isSuccess: isVerifySuccess,
  } = useQuery(
    ['payments/verify', orderId, serviceId],
    () => verifyPayment({ orderId, serviceId, hash }),
    { retry: false, refetchOnWindowFocus: false, refetchOnMount: false },
  )

  const {
    data: paymentDetails,
    isLoading: isLoadingPaymentDetails,
    isError: isPaymentDetailsError,
    isSuccess: isPaymentDetailsSuccess,
  } = useQuery(
    ['getPaymentDetails', orderId],
    () => getPaymentDetails(orderId),
    {
      enabled: isVerifySuccess,
      onSuccess: (data: PaymentDetailsResponseType) => {
        data.status !== 'pending' && setShouldRefetch(false)
        return data
      },
      select: responseDataSelector,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: shouldRefetch && 1000,
      refetchOnReconnect: false,
      cacheTime: 0,
    },
  )

  const {
    name,
    isLoading: isLoadingExtraDetails,
    isSuccess: isLoadingExtraDetailsSuccess,
  } = useFetchParishOrGoalDetails({
    parishId: paymentDetails?.parish_id ? paymentDetails.parish_id : '',
    goalId: paymentDetails?.goal_id
      ? paymentDetails.goal_id
      : paymentDetails?.parish_id
      ? paymentDetails.parish_id
      : '',
    readyToFetchData: isPaymentDetailsSuccess,
  })

  const isLoading =
    !ready ||
    isLoadingVerify ||
    isLoadingPaymentDetails ||
    isLoadingExtraDetails ||
    paymentDetails?.status === 'pending'

  const isSuccess =
    ready &&
    !isLoadingVerify &&
    isVerifySuccess &&
    paymentDetails?.status === 'success'

  const isError =
    ready &&
    (isVerifyError ||
      isPaymentDetailsError ||
      paymentDetails?.status === 'failure')

  return (
    <>
      {isLoading && (
        <Feedback
          title="Operacja w toku"
          content="Twoja płatność,"
          extraContent="jest przetwarzana">
          <LoaderWithLogo />
        </Feedback>
      )}
      {isSuccess && (
        <Feedback
          title={'Gratulacje!'}
          content={
            paymentDetails?.amount.value
              ? `Przekazałeś ${Number(paymentDetails.amount.value).toFixed()} ${
                  paymentDetails.amount.currency_iso.iso_code
                } na:`
              : ''
          }
          extraContent={isLoadingExtraDetailsSuccess ? `"${name}"` : ''}
          boldedContent>
          <PaymentDoneIcon />
          <div className="mt-4" style={{ overflowWrap: 'anywhere' }}>
            <p className="text-lg font-medium">
              Twoja pomoc została przekazana. Właśnie przyczyniłeś się do
              rozwoju czegoś wielkiego
            </p>
            <Button buttonStyle="underlined">
              <a href="mailto:kontakt@mkosciol.pl">
                Wyślij wiadomość twórcom aplikacji
              </a>
            </Button>
          </div>
        </Feedback>
      )}
      {isError && (
        <Feedback
          title={'Coś poszło nie tak!'}
          content="Nie udało się potwierdzić płatności.">
          <ErrorIcon />
        </Feedback>
      )}
    </>
  )
}
