import { FC } from 'react'
import styles from './WipPlaceholder.module.css'

type Props = {
   text?: string;
};

export const WipPlaceholder: FC<Props> = (props: Props) => {
   return (
     <div className={styles.container}>
        <h1 className={styles.text}>{props.text}</h1>
     </div>
   )
}

WipPlaceholder.defaultProps = {
   text: 'Szczęść Boże',
}
