import { ReactComponent as Logo } from 'assets/logo.svg'
import { ReactComponent as BackArrow } from 'assets/arrow_back.svg'
import { FC } from 'react'
import { useHistory } from 'react-router-dom'

interface FormHeaderProps {
  title: string
  subtitle: string
}

export const FormHeader: FC<FormHeaderProps> = ({ title, subtitle }) => {
  const history = useHistory()

  return (
    <div className="relative flex flex-col items-center m-8 mb-2">
      <BackArrow
        className="absolute left-0 top-0 w-6 h-6 cursor-pointer"
        onClick={() => history.goBack()}
      />
      <div className="rounded-full shadow-logo">
        <Logo className="w-16 h-16" />
      </div>
      <p className="p-4 pb-0 text-xl font-bold">{title}</p>
      <p className="p-4 text-center text-md font-medium">{subtitle}</p>
    </div>
  )
}
