import { FC } from 'react'
import { GoalComponent } from 'components'
import { useQuery } from 'react-query'
import { fetchParishGoals } from 'services'
import { Loader } from '../../Loader/Loader'
import { Goal } from '../types'
import { responseDataSelector } from 'utils'

export interface GoalsListProps {
  parishId: string
}

export const GoalsList: FC<GoalsListProps> = ({ parishId }) => {
  const { data: goals, isLoading, isLoadingError, error } = useQuery(
    ['parishGoalsList', parishId],
    () => fetchParishGoals(parishId),
    { select: responseDataSelector }
  )

  return (
    <div className="flex flex-col items-center w-full">
      {isLoading && !isLoadingError ? (
        <Loader />
      ) : goals && goals.length > 0 ? (
        goals.map((goal: Goal) => <GoalComponent key={goal.id} goal={goal} />)
      ) : (
        <p>Brak danych do wyświetlenia</p>
      )}
      {isLoadingError && (
        <p>{`wystąpił błąd podczas ładowania danych, ${error}`}</p>
      )}
    </div>
  )
}
