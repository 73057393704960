import { useForm } from 'react-hook-form'
import { Button, FormHeader, InfoElement, TextInput } from 'components'
import { emailRegExp } from '../../constants'
import { useMutation } from 'react-query'
import { login, ResponseError, UserLogin } from 'services'
import { useState } from 'react'
import { useAuth } from '../../hooks'
import { useHistory, useLocation } from 'react-router-dom'
import clsx from 'clsx'
import { GAEvent } from 'utils/googleAnalyticsUtils'
import { AxiosError } from 'axios'

export const LoginScreen = () => {
  const isPoC = true
  const [errorDetails, setErrorDetails] = useState('')
  const auth = useAuth()
  let history = useHistory()
  let location = useLocation()

  let { from }: any = location.state || { from: { pathname: '/' } }

  const { register, errors, handleSubmit } = useForm({
    mode: 'onTouched',
  })

  const mutation = useMutation((user: UserLogin) => login(user), {
    onSuccess: (data) => {
      auth.signIn(data.headers.authorization)
      GAEvent('Logowanie', 'Zalogowano użytkownika', undefined, true)
      history.replace(from)
    },
    onError: (error: AxiosError) => {
      const errorDetails = error.response?.data.errors.find(
        (error: ResponseError) => error.source === 'user',
      ).details
      setErrorDetails(errorDetails)
    },
  })

  const onSubmit = (values: UserLogin) => {
    GAEvent('Logowanie', 'Wciśnięto przycisk logowania')
    mutation.mutate(values)
  }

  return (
    <div className="flex flex-col flex-shrink">
      <FormHeader
        title="Zaloguj się"
        subtitle="I wspieraj swoją parafię kiedy tylko chcesz"
      />
      <form
        className="flex flex-col items-center justify-center mx-6"
        onSubmit={handleSubmit(onSubmit)}
        noValidate>
        <TextInput
          label="E-mail"
          placeholder="Wpisz adres e-mail"
          type="email"
          name="email"
          defaultValue=""
          ref={register({
            required: true,
            pattern: emailRegExp,
          })}
          error={errors.email && 'Niepoprawny adres email'}
        />
        <TextInput
          placeholder="Podaj hasło"
          label="Hasło"
          type="password"
          name="password"
          defaultValue=""
          ref={register({
            required: true,
          })}
          error={errors.password && 'Hasło wymagane'}
        />
        {isPoC || (
          <Button
            type="button"
            buttonStyle="underlined"
            disabled={mutation.isLoading}
            fontSize="md">
            Przypomnij hasło
          </Button>
        )}
        <div className={clsx(mutation.isError ? 'opacity-100' : 'opacity-0')}>
          <InfoElement content={errorDetails} error={!!errorDetails} />
        </div>
        <Button
          type="submit"
          disabled={mutation.isLoading}
          optionalStyles="mt-7">
          Zaloguj się
        </Button>
        <Button
          type="button"
          buttonStyle="underlined"
          onButtonClick={() => {
            GAEvent('Logowanie', 'Przejście do widoku rejestracji')
            history.push('/registration')
          }}
          disabled={mutation.isLoading}>
          Przejdź do rejestracji
        </Button>
      </form>
    </div>
  )
}
