import { FC, ReactElement, useState } from 'react'
import { getAddressDetails } from 'utils'
import styles from './ParishCard.module.css'
import { ReactComponent as ChurchIcon } from 'assets/church.svg'

export type ParishCardProps = {
  id: string
  name: string
  address: string
  children?: ReactElement
  image_path?: string
}

export const ParishCard: FC<ParishCardProps> = ({
  address,
  name,
  image_path,
  children,
}) => {
  const [street, city] = getAddressDetails(address)
  const [showPhoto, setShowPhoto] = useState(true)

  return (
    <div className={styles.outerContainer}>
      <div className={styles.container}>
        {showPhoto ? (
          <img
            src={`https://api.stage.mkosciol.binar.app/${image_path}`}
            className={styles.image}
            alt="Fotografia kościoła"
            onError={() => setShowPhoto(false)}
          />
        ) : (
          <div className={styles.churchContainer}>
            <ChurchIcon />
          </div>
        )}
        <div className={styles.textContainer}>
          <p className={styles.nameText}>{name}</p>
          <p className={styles.addressText}>ul.{street}</p>
          <p className={styles.addressText}>{city}</p>
        </div>
      </div>

      {children}
    </div>
  )
}
