import React, { FC } from 'react'
import { ReactComponent as HamburgerIcon } from 'assets/hamburger.svg'
import { ReactComponent as CloseIcon } from 'assets/close.svg'
import { AppHeader, AppMenu } from 'components'
import { useToggle } from 'hooks'
import styles from './AppHeaderWithMenu.module.css'
import { GAEvent } from 'utils/googleAnalyticsUtils'

type AppHeaderWithMenuProps = {
  optionalStyles?: React.CSSProperties
  hideLeftIcon?: boolean
}

export const AppHeaderWithMenu: FC<AppHeaderWithMenuProps> = ({
  children,
  optionalStyles = {},
  hideLeftIcon = false,
}) => {
  const { on: menuOn, toggle: toggleMenu } = useToggle()

  return (
    <div className={styles.screenContainer} style={optionalStyles}>
      <AppHeader
        background={!menuOn}
        hideLeftIcon={hideLeftIcon ? hideLeftIcon : menuOn}
        rightIcon={
          menuOn ? (
            <CloseIcon onClick={toggleMenu} className={styles.icon} />
          ) : (
            <HamburgerIcon
              onClick={() => {
                GAEvent('Global', 'Rozwinięcie menu')
                toggleMenu()
              }}
              className={styles.icon}
            />
          )
        }
      />
      <div className={styles.contentContainer}>{children}</div>
      <AppMenu toggleMenu={toggleMenu} menuOn={menuOn} />
    </div>
  )
}
