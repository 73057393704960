export const accountVerifyContent = {
  processing: 'trwa weryfikacja...',
  success:
    'Rejestracja przebiegła pomyślnie, teraz możesz cieszyć się w pełni aplikacją i wspierać rozwój Kościoła',
  errorFirstLine: 'Na szczęście Bóg czuwa',
  errorSecondLine: 'na Niego zawsze możesz liczyć',
}

export const accountVerifyTitle = {
  processing: 'Proszę czekać',
  success: 'Gratulacje!',
  error: 'Ups... Coś poszło nie tak.',
}
