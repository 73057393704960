import { createContext, FC } from 'react'
import { AuthContext, useProvideAuth } from 'hooks'

const authContextInitialValues = {
  signIn: (_: string) => {},
  signOut: () => {},
  isAuthenticated: null,
}

export const authContext = createContext<AuthContext>(authContextInitialValues)

export const AuthProvider: FC = ({ children }) => {
  const auth = useProvideAuth()

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}
