import { useState, createRef, useLayoutEffect, RefObject } from 'react';

type ReturnType = {
  height: number;
  ref: RefObject<HTMLDivElement>;
};

export const useComponentHeight = (): ReturnType => {
  const [height, setHeight] = useState(0);
  const ref = createRef<HTMLDivElement>();

  useLayoutEffect(() => {
    if (ref.current) {
      setHeight(ref.current.clientHeight);
    }
  }, [ref]);

  return { height, ref };
};
