import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  AccountVerifyScreen,
  GoalDetails,
  LoginScreen,
  ParishDetails,
  ParishesScreen,
  ParishGoals,
  PaymentHistory,
  PaymentReturnScreen,
  PaymentScreen,
  RegistrationMailSentScreen,
  RegistrationScreen,
  WipPlaceholder,
} from 'screens'
import { useInitializeGA } from 'hooks/useInitializeGA'
import { ParishAccountActivation } from 'screens/ParishAccountActivation/ParishAccountActivation'
import { PrivateRoute } from 'components'

function App() {
  useInitializeGA()

  return (
    <div className="container mx-auto max-w-lg font-sans">
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to="/parishes" />
          </Route>
          <Route
            path="/choose-payment-method/:parishId/:goalId/:amount"
            children={<PaymentScreen />}
          />
          <Route path="/payments" children={<PaymentReturnScreen />} />
          <Route
            exact
            path="/account/verify/:confirmation_token?"
            children={<AccountVerifyScreen />}
          />
          <Route
            path="/registerMail"
            children={<RegistrationMailSentScreen />}
          />
          <Route
            exact
            path="/parishes/:parish_id/goals/:id"
            children={<GoalDetails />}
          />
          <Route exact path="/parishes/:id/goals" children={<ParishGoals />} />
          <Route exact path="/parishes/:id" children={<ParishDetails />} />
          <Route
            exact
            path="/accounts"
            children={<ParishAccountActivation />}
          />
          <Route exact path="/parishes" children={<ParishesScreen />} />
          <PrivateRoute
            exact
            path="/user/payment-history"
            children={<PaymentHistory />}
          />
          <Route path="/registration" children={<RegistrationScreen />} />
          <Route path="/login" children={<LoginScreen />} />
          <Route path="*" children={<WipPlaceholder text="404" />} />
        </Switch>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </div>
  )
}

export default App
