import { format, fromUnixTime } from 'date-fns'
import { ibanNumberMatcher } from '../constants'

export const convertUnixTimestampStringToDateString = (
  timestamp: string,
): string => format(fromUnixTime(Number(timestamp)), 'dd.MM.yyyy')

export const formatIbanNumber = (iban: string | undefined) => {
  const ibanMatcher = iban?.match(ibanNumberMatcher)

  if (ibanMatcher) return ibanMatcher.join(' ')

  return iban
}
